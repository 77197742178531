import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useHistoricoMaterialesList() {
  // Use toast
  const toast = useToast()

  const refHistoricoMaterialesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'product_ref', label: 'REF. Produto', sortable: false },
    { key: 'product_description', label: 'Descripcion', sortable: false },
    { key: 'delivery_date', label: 'Fecha de Recepción', sortable: false },
    { key: 'pedido_num', label: 'Nº de pedido', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]
  const perPage = ref(50)
  const totalHistoricoMateriales = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refHistoricoMaterialesListTable.value ? refHistoricoMaterialesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalHistoricoMateriales.value,
    }
  })

  const refetchData = () => {
    refHistoricoMaterialesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchHistoricoMateriales = (ctx, callback) => {
    store
      .dispatch('app-historico-materiales/fetchHistoricoMateriales', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
        },
        headers: {},
        query: {
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalHistoricoMateriales.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching materiales list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listMateriales = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-historico-materiales/listHistoricoMateriales', {
        body: {
          search: searchQuery.value,
        },
        headers: {
          'X-Sort': `historico_materiales.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {},
      })
      .then(res => {
        const { total } = res
        totalHistoricoMateriales.value = total
        resolve(res.data)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching materiales list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchHistoricoMateriales,
    tableColumns,
    perPage,
    currentPage,
    totalHistoricoMateriales,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refHistoricoMaterialesListTable,

    refetchData,
    listMateriales,
  }
}
